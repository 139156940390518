  
  .run-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  
  .run-content {
    background: white;
    padding: 20px;
    width: 30%;
    max-width: 800px;
    border-radius: 8px;
    position: relative;
  }
  .run-content h2{
    color: #2B3674;
    font-size: 19px;
    font-weight: 700;
    border-bottom: 1px solid #E0E5F2;
    padding-bottom: 10px;
  }
  .quantity-div{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding-top: 10px;
    align-items: center;
  }
  .quantity-div span{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
  }
  .quantity-div input{
    color: #2B3674;
    font-size: 24px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #E0E5F2;
    outline: none;
    padding: 2px 10px;
    width: 30%;
  }
  .quantity-capital{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .quantity-capital  span:first-child{
    color: #4318FF;
    font-size: 12px;
    font-weight: 500;
  }
  .quantity-capital  span:nth-child(2){
    color: #A3AED0;
    font-size: 10px;
    font-weight: 500;
  }
  .quantity-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap:20px;
    margin-top: 10px;
  }
  .quantity-actions button:nth-child(1){
    border: 1px solid rgba(86,103,137,0.26);
    border-radius: 5px;
    padding:15px 10px;
    width:50%;
    background-color: transparent;
    cursor: pointer;
    color: rgba(21, 25, 32,0.5);
    font-size: 14px;
    font-weight: 600;
  }
  .quantity-actions button:nth-child(2){
    border: none;
    border-radius: 5px;
    padding:15px 10px;
    width:50%;
    background-color: #2B4BF2;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
  }
  
  
 
 

  @media (max-width: 575.98px) {
    .run-content {
        background: white;
        padding: 20px;
        width: 80%;
        max-width: 800px;
        border-radius: 8px;
        position: relative;
      }
  }
  
  /* Small devices (landscape phones, 576px to 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
   
  }
  
  /* Medium devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    
  }
  
  