* {
    margin: 0;
    padding: 0;
}
.plans-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.plans-heading {
    color: #1C1C1C;
    font-size: 35px;
    font-weight: 600;
}

.plans-subheading {
    color: #879AB6;
    margin-top: 3px;
    font-size: 18px;
    font-weight: 400;
}

.plans-container {
    display: flex;
    padding: 15px 0px 80px 0px;
}

.plans-left {
    width: 30%;
}

.globe-image {
    margin-top: 30px;
    width: 30%;
    height: 30%;
}

.plans-features {
    margin-right: 10px;
    text-align: right;
}

.plans-features ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 30px;
}

.plans-features li {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;
}

.plans-content {
    width: 60%;
    position: relative;
}
.small-screen-tick{
    display: none;
}

.billing-box {
    padding: 10px 10px;
    display: flex;
    margin: 25px auto;
    border: 1px solid #4318FF;
    border-radius: 10px;
    gap: 10px;
    position: relative;
    height: 120px;
}

.billing-option {
    flex: 1;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.billing-monthly {
    background-color: #ffffff;
    color: #4318FF;
}

.billing-yearly {
    background-color: #4318FF;
    color: #ffffff;
    border-radius: 0px 10px 10px 0px;
}
.billing-option{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    gap:15px;
}
.billing-monthly .price-div{
    color: #4318FF;
    font-size: 35px;
    font-weight: 600;
}
.billing-monthly .price-div span{
    font-size: 18px;
    font-weight: 600;
}

.billing-option span {
    font-weight: 600;
}
.billing-yearly .price-div{
    color: #FFFFFF;
    font-size: 35px;
    font-weight: 600;
}
.billing-yearly .price-div span{
    color: #A3AED0;
    font-size: 18px;
    font-weight: 600;
}

.b-discount {
    background-color: #ffd700;
    color: #E00000;
    padding: 2px 8px;
    border-radius: 20px;
    font-size: 8px;
    margin-left: 6px;
    font-weight: 300;
    position: relative;
    bottom: 3px;
}

.features-and-plans-container {
    display: flex;
    margin: 15px auto;
    gap:4%;
}

.features-list {
    width: 30%;
    text-align: center;
    border: 1px solid #4318FF;
    border-radius: 12px;
    padding: 15px 15px 0px 15px;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    
}

.features-list h3 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
}

.checkmarks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin-top: 20px; 
}

.checkmarks-container svg {
    font-size: 1.8em;
    color: #4318FF;
}

.plans {
    width: 110%;
    display: flex;
    flex-direction: column;
}

.plan-option {
    border: 1px solid #4318FF;
    border-radius: 9px;
    padding: 25px 35px;
    margin-bottom: 10px;
    text-align: left;
    transition: 0.3s ease;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}

.plan-option.selected {
    border: 1px solid;
    background-color: #4318FF;
}

.plan-option .icon {
    font-size: 25px;
    color: #4318FF;
}

.plan-option.selected .icon {
    color: #fff;
}

.plan-option .details {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    gap: 5px;
    align-items: start;
    flex: 1;
    margin-left: 5%;
}

.plan-option .details p {
    font-size: 20px;
    font-weight: 600;
    color: #101733;
}
.plan-option.selected .details p {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}


.plan-option.selected .price {
    color: white;
}

.plan-option .details .savings {
    display: inline-block;
    font-size: 15.5px;
    background-color: #DAE8FF;
    color: #4318FF;
    padding: 2px 12px;
    border-radius: 30px;
    font-weight: 500;
}
.plan-option.selected .details .savings {
    display: inline-block;
    font-size: 15.5px;
    background-color: #DAE8FF;
    color: #4318FF;
    padding: 2px 12px;
    border-radius: 30px;
    font-weight: 500;
}

.plan-option .price {
    color: #000000;
    font-size: 36px;
    font-weight: 600;
}
.plan-option .price span {
    color: #656565;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    bottom: 5px;
    left: 5px;
}
.plan-option.selected .price {
    color: white;
}
.plan-option.selected .price span {
    color: #E7E7E7;
}


.bottom-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #DAE8FF;
    padding: 20px 20px;
    display: flex;
    justify-content: end;
    gap: 60px;
    z-index: 1;
}

.bottom-section .investment-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bottom-section .investment-details p {
    color: #101733;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.bottom-section .investment-details .savings {
    display: inline-block;
    font-size: 15.5px;
    font-weight: 500;
    background-color: #ffffff;
    color: #4318FF;
    padding: 2px 12px;
    border-radius: 20px;
    white-space: nowrap;
}

.bottom-section .price-and-button {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-right: 7%;
}

.bottom-section h3 {
    font-size: 36px;
    color: #000000;
    font-weight: 600;
    margin: 0;
}
.bottom-section h3 span {
    font-size: 13px;
    color: #656565;
    font-weight: 600;
}

.subscribe-button {
    background-color: #4318FF;
    color: #fff;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: 600;
}

.subscribe-button:hover {
    background-color: #2f3bd3;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full page height for centering */
    background-color: #f0f8ff; /* Light blue background for subtle theme */
}

.blue-loader {
    border: 6px solid #e3f2fd; /* Light blue-gray border */
    border-top: 6px solid #1e88e5; /* Deep blue border */
    border-radius: 50%; /* Circle shape */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Spinning effect */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Extra small devices (phones, <576px) */
@media (max-width: 575.98px) {
    .plans-header {
        margin-top: 30px;
        margin-left: 25px;
        width: 90%;
        display: block;
    }
    .plans-heading {
        font-size: 20px;
    }
    .plans-subheading {
        margin-top: 10px;
        font-size: 12px;
    }
    .plans-container{
        display: block;
        padding-top: 0px;
    }
    .plans-left{
        width: 90%;
    }
    .globe-image{
        display: none;
    }
    .plans-features {
        margin-left: 25px;
        margin-right: 0px;
        text-align: left;
    }
    .plans-features li {
        margin-bottom: 10px;
        font-size: 10px;
        display: flex;
    }
    .small-screen-tick{
        display: block;
        width: 15px;
        height: 15px;
        color: #4318FF;
        margin-right: 10px;
    }
    .plans-content{
        width: 85%;
        margin-left: 25px;
    }
    .billing-box {
        padding: 5px 5px;
        margin: 5px auto;
        gap: 5px;
        width: 95%;
        height: auto;
    }
    .billing-option {
        width: 100%;
        font-size: 14px;
        gap: 10px;
        margin:0px;
        padding:10px 5px;
        height: auto;
    }
    .billing-monthly .price-div span {
        font-size: 10px;
    }
    .billing-yearly .price-div span {
        font-size: 10px;
    }
    .billing-monthly .price-div {
        font-size: 14px;
    }
    .billing-yearly .price-div {
        font-size: 14px;
        font-weight: 600;
    }
    .b-discount {
        display: block;
        padding: 2px 8px;
        font-size: 10px;
        bottom: 0px;
        margin-top: 2px;
    }
    .features-and-plans-container {
        display: block;
    }
    .features-list{
        display: none;
    }
    .plans{
        width: 100%;
        margin-bottom: 200px;
    }
    .plan-option {
        padding: 10px 10px;
    }
    .plan-option .details  {
        white-space: wrap;
        max-width: 50%;
        position: relative;
        right: 0%;
    }
    .plan-option .details p{
        font-size: 14px;
    }
    .plan-option .details .savings {
        font-size: 12.5px;
    }
    .plan-option.selected .details  {
        white-space: wrap;
        max-width: 50%;
        position: relative;
        right: 0%;
    }
    .plan-option.selected .details p{
        font-size: 14px;
    }
    .plan-option.selected .details .savings {
        font-size: 12.5px;
    }
    .plan-option.selected .price2  {
        font-size: 14px;
    }
    .plan-option .price2  {
        font-size: 14px;
    }
    .plan-option .price {
        font-size: 16px;
    }
    .plan-option.selected .price {
        font-size: 16px;
    }
    .plan-option .price span{
        position: relative;
        bottom:1px;
    }
    .plan-option.selected .price span {
        position: relative;
        bottom:1px;
    }
    .bottom-section {
        
        padding: 10px 20px;
        display: block;
    }
    .bottom-section .investment-details p {
        font-size: 18px;
        margin-bottom: 8px;
    }
    .bottom-section .investment-details .savings {
        font-size: 15.5px;
        padding: 2px 20px;
    }
    .bottom-section .price-and-button {
        display: flex;
        align-items: start;
        gap: 20px;
        margin-top: 20px;
        margin-right: 0%;
    }
    .bottom-section h3{
        font-size: 20px;
        margin-top: 5px;
    }
    .bottom-section h3 span {
        font-size: 15px;
    }
    .subscribe-button {
        padding: 12px 18px;
        font-size: 15px;
        margin-right: 4px;
    }
  }
  
  /* Small devices (landscape phones, 576px to 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .globe-image{
        height: 20%;
        width: 45%;
    }
    .plans-features ul {
        margin-top: 105px;
    }
    .plans-features li {
        font-size: 14px;
        margin-bottom: 45px;
    }
    .plans-heading {
        margin-left: 10%;
        font-size: 24px;
    }
    .plans-subheading {
        font-size: 15px;
        margin-left: 10%;
    }
    .billing-box {
        width: 110%;
        height: 90px;
    }
    .billing-option {
        font-size: 16px;
        gap: 10px;
        padding:10px
    }
    .billing-monthly .price-div {
        font-size: 25px;
    }
    .billing-monthly .price-div span {
        font-size: 14px;
    }
    .billing-yearly .price-div {
        font-size: 25px;
    }
    .billing-yearly .price-div span {
        font-size: 14px;
    }
    .plans{
        width: 80%;
    }
    .plan-option {
        display: block;
        padding: 15px 15px;
    }
    .plan-option .details{
        width: 50%;
    }
    
    .plan-option .details p {
        font-size: 15px;
        width:90%;
        padding-right: 40px;
    }
    .plan-option.selected .details p {
        font-size: 15px;
        width:90%;
        padding-right: 20px;
    }
    .plan-option .price {
        font-size: 18px;
        width: 40%;
        margin-right: 20px;
    }
    .plan-option .details .savings {
        width: 50%;
    }
    .plan-option.selected .details .savings {
        width: 50%;
    }
    
  }
  
  /* Medium devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .globe-image{
        height: 20%;
    }
    .plans-features ul {
        margin-top: 105px;
    }
    .plans-features li {
        font-size: 14px;
        margin-bottom: 45px;
    }
  }
  