/* src/components/TradeList.css */

.trade-list {
    flex: 2;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 20px;
    
  }
  .trade-list h3{
    color: #2B3674;
    font-size: 20px;
    font-weight: bold;
    margin:0px;
    margin-bottom: 10px;
  }
  .tabs {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #E9EDF7;
  }
  .tabs .active{
    background: none;
    font-size: 16px;
    color: #666;
    border: none;
    font-weight: 500;
    color: #A3AED0;
    border-bottom: 2px solid #4318FF;
    padding:5px 9px;
  }
  .tabs button{
    background: none;
    font-size: 16px;
    color: #666;
    border: none;
    font-weight: 500;
    color: #A3AED0;
    padding:5px 9px;
    cursor: pointer;
  }
  
  
  /* .tabs .active {
    color: #333;
    border-bottom: 2px solid #5858ff;
  } */
  
  .trade-list ul {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    height: 220px;
    overflow-y: scroll;
  }
  .trade-list ul li {
    margin-bottom: 10px;
  }
  
  .trade-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .trade-details {
    flex:6;
    margin-bottom: 5px;
  }
  
  .trade-name {
    font-weight: 700;
    color: #2B3674;
    font-size: 17px;
    margin:0px;
  }
  .trade-name span {
    font-weight: 500;
    color: #7C7C7C;
    font-size: 12px;
    margin-left:5px;
  }
  
  .trade-invested {
    font-size: 14px;
    color: #A3AED0;
    font-weight: 400;
    margin: 0px;
    margin-top: 5px;
  }
  .trade-invested span {
    margin-left: 10px;
  }
  
  .trade-profit {
    display: flex;
    flex-direction: column;
    margin:0px;
    align-items: end;
  }
  .profit-amount{
    color: #00C951;
    font-size: 18px;
    font-weight: bold;
    margin:0px;
    margin-bottom: 5px;
  }
  .profit-percentage{
    color: #00C951;
    font-size: 12px;
    font-weight: 500;
    margin:0px;
  }
  
  .loss-amount{
    color: #E23F44;
    font-size: 18px;
    font-weight: bold;
    margin:0px;
    margin-bottom: 5px;
  }
  .loss-percentage{
    color: #E23F44;
    font-size: 12px;
    font-weight: 500;
    margin:0px;
  }
  .trade-caret{
    margin-right: 3px;
    position: relative;
    top:1px;
  }
  .trade-caret.negative{
    margin-right: 3px;
    position: relative;
    top:0px;
  }
  .trade-holder{
    color: #A3AED0;
    font-size: 15px;
  }

  @media (max-width: 576px){
    .trade-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
    }
    .trade-name span {
      font-weight: 400;
      color: #7C7C7C;
      font-size: 11px;
      margin-left:5px;
    }
    .trade-invested {
      font-size: 12px;
      margin-top: 3px;
    }
    .trade-invested span {
      display: block;
      margin-left: 0px;
    }
    .profit-amount {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    .profit-percentage {
      font-size: 10px;
    }
  }
  