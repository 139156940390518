.dashboard{
    background-color: #F4F7FF;
    padding: 35px 90px !important;
    overflow-x: hidden;
}
.main-content{
    display: flex;
    flex-direction: row;
    gap:2%;
}
.top-notify{
    border-radius: 16px;
    background-color: #FFF1E4;
    width:57%;
    height: 60px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap:10px;
}
.notify-icon{
    color: #FF902F;
    font-size: 20px;
}
.refresh-icon{
  color: #FF902F;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  top:2px;
}

.notify-text{
    font-size: 16px;
    font-weight: 500;
    color: #FF902F;
}
.top-notify button{
    color: white;
    border-radius: 8px;
    background-color: #2B4BF2;
    border:none;
    font-size: 14px;
    font-weight: 600;
    height: 35px;
    width: 120px;
    cursor: pointer;
}
.dash-footer{
  font-size: 12px;
  margin-top: 20px;
}

@media (max-width: 575.98px) {
    .dashboard{
        padding: 25px 25px !important;
      }
      .top-notify{
        width: 85%;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .notify-text{
        font-size: 12px;
      }
      .top-notify button{
        font-size: 9px;
        height: 30px;
        width:150px;
      }
      
      .main-content{
        display: block;
      }
      .insights-card{
        height: 120px;
        padding: 0px 20px 20px 20px;
        margin-bottom: 10px;
      }
      .card-content1 h3 {
        font-size: 18px;
        margin-top: 25px;
        margin-bottom: 10px;
      }
      .card-content2 h4 {
        font-size: 18px;
      }
      .current-amount {
        font-size: 20px;
      }
      .gain {
        font-size: 14px;
        margin-top: 0px;
      }
      .gain-icon{
        display: none;
      }
      .gain-percentage{
        display: none;
      }
      .invested-amount {
        font-size: 16px;
      }
      .refresh-icon{
        color: #FF902F;
        font-size: 20px;
        cursor: pointer;
        margin-left: 0px;
        position: relative;
        top:5px;
      }
      .refresh-div{
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .refresh-div button{
        width:100%
      }
}

/* Small devices (landscape phones, 576px to 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .dashboard{
    padding: 35px 40px !important;
  }
  .top-notify{
    width: 93%;
  }
  .main-content{
    display: block;
  }
  .insights-card{
    height: 160px;
    padding: 0px 20px 20px 20px;
    margin-bottom: 10px;
  }
  .refresh-icon{
    color: #FF902F;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    top:7px;
  }
  .refresh-div{
    display: flex;
    flex-direction: row;
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .refresh-icon{
    color: #FF902F;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    top:7px;
  }
  .refresh-div{
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 991.98px) and (max-width: 1200.98px) {
  .refresh-icon{
    color: #FF902F;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    top:7px;
  }
  .refresh-div{
    display: flex;
    flex-direction: row;
  }
}

