/* src/components/Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 0px 20px 0px;
    background-color: #F4F7FF;
    overflow-x: hidden;
}

.actions {
    display: flex;
    gap: 15px;
}
.header-btn{
    background-color: white;
    border-radius: 100px;
    width: 170px;
    height: 60px;
    border: none;
    font-family: 'DM Sans', sans-serif !important;
}
.balance{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.balance .balance-symbol{
    width: 40px;
    height: 40px;
    background-color: #F4F7FE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.balance-text{
    display: flex;
    flex-direction: column;
    gap:2px;
    align-items:start;
}
.balance-text1{
    color: #A3AED0;
    font-size: 10px;
    font-weight: 500;
}
.balance-text2{
    color: #2B3674;
    font-size: 16px;
    font-weight: bold;
}



.add-money {
    display: flex;
    flex-direction: row;
    gap:20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.add-money span{
    color: #3D12E9;
    font-size: 14px;
    font-weight: bold;
}

.add-money img{
    width: 20px;
}

.kyc-verified {
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.kyc-verified span{
    color: #00C951;
    font-size: 14px;
    font-weight: bold;
}

.kyc-verified div{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(200,200,200);
}
.logo-image{
    width: 40px;
}
.logo-image-title{
    height: 30px;
    position: relative;
    bottom: 5px;
    left: 12px;
}
.logout-option{
    position: 'absolute';
    top: 100%;
    right: 0px;
    background-color: #fff;
    border: 1px solid #A3AED0;
    color: #A3AED0;
    padding: 10px;
    cursor: pointer;
    z-index:1;
    border-radius: 10px;
}


/* Extra small devices (phones, <576px) */
@media (max-width: 575.98px) {
    .header{
        display: block;
    }
    .logo{
        margin-bottom: 10px;
    }
    .logo-image{
        width: 25px;
    }
    .logo-image-title{
        height: 15px;
    }
    .header-btn{
        width: auto;
        height: 30px;
    }
    .kyc-verified{
        padding:0px 10px;
    }
    .kyc-verified div{
        display: none;
    }
    .kyc-verified span{
        font-size: 10px;
    }
    .actions {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
    .balance-text1{
        display: none;
    }
    .balance {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
    }
    .balance .balance-symbol {
        display: none;
    }
    .balance-text{
        padding-left: 10px;
        padding-right: 10px;
    }
    .add-money img {
        display: none;
    }
    .add-money{
        padding-left: 10px;
        padding-right: 10px;
    }
  }
  
  /* Small devices (landscape phones, 576px to 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .logo-image{
        width: 25px;
    }
    .logo-image-title{
        height: 15px;
    }
    .header-btn{
        width: 100px;
        height: 30px;
    }
    .kyc-verified div{
        display: none;
    }
    .kyc-verified span{
        font-size: 10px;
    }
  }
  
  /* Medium devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    
  }

  