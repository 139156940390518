/* src/components/AlgosList.css */

.algos-list {
    width: 100%;
    background-color: #ffffff;
    border-radius: 16px;
    margin-top: 20px;
  }
  .algos-header{
    background-image: url("../assets/algos.png");
    background-size: 100% 100%;
    width:100%;
    height: 130px;
    
  }
  .algos-header h3{
    color: white;
    font-size: 34px;
    font-weight: bold;
    padding-top: 20px;
    margin:0px;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  
  .algo-tabs {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-left: 30px;
  }
  .tab-all{
    background-color: white;
    border: none;
    font-size: 15px;
    color: #4318FF;
    border-radius: 70px;
    width: 60px;
    padding: 4px 0px;
    cursor: pointer;
  }
  
  .tab-live{
    background: none;
    border:none;
    font-size: 15px;
    color: #FFFFFF;
    cursor: pointer;
    width: 60px;
  }
  
  .algo-cards {
    display: flex;
    gap: 20px;
    flex-direction: row;
    padding: 30px 40px;
  }
  
  @media (max-width: 575.98px) {
    .algo-cards {
        display: block;
        padding: 10px 20px;
    }
   
  }
  
  /* Small devices (landscape phones, 576px to 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    
  }
  
  /* Medium devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    
  }
  