/* src/components/InsightsCard.css */

.insights-card {
    flex: 3;
    background-image: url("../assets/insights.png") ;
    background-size: 100% 100%;
    background-color: #3D12E9;
    border-radius: 16px;
    color: #fff;
    padding: 25px 40px;
    display: flex;
    flex-direction: row;
    height: 300px;
  }
  .card-content1{
    flex:6;
  }
  .card-content2{
    flex:4;
  }
  .card-content1 h3{
    color: #A3AED0;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .current-amount {
    font-size: 44px;
    font-weight: 700;
    margin: 0px 0;
  }
  .gain {
    font-size: 24px;
    color: #00C951;
    font-weight: bold;
    margin: 0px;
    margin-top:5px
  }
  .gain-icon{
    margin-left: 15px;
    position: relative;
    top:3px;
    margin-right: 2px;
  }
  
  .gain-percentage {
    font-size: 22px;
    color: #00C951;
    font-weight: bold;
    margin: 0px;
    margin-top:5px
  }
  .loss {
    font-size: 24px;
    color: #DC143C;
    font-weight: bold;
    margin: 0px;
    margin-top:5px
  }
  .loss-icon{
    margin-left: 15px;
    position: relative;
    top:3px;
    margin-right: 2px;
    color: #DC143C;
  }
  
  .loss-percentage {
    font-size: 22px;
    color: #DC143C;
    font-weight: bold;
    margin: 0px;
    margin-top:5px
  }
  .card-content2 h4{
    color: #A3AED0;
    font-size: 24px;
    font-weight: 400;
    margin:0px;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  
  
  .invested-amount {
    margin:0px;
    font-size: 36px;
    font-weight: bold;
  }
  
  
  