@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.landing-container {
  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: row;
  font-family: 'DM Sans', sans-serif !important;
  overflow: hidden;
}

.login-section {
  margin: 0 auto;
  width: 50%;
  margin-top: 14%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.login-main-section {
  margin-left: 20%;
  width: 50%;
}
.logo-login{
  display: none;
}

h2 {
  width: 100%;
  font-size: 32px;
  text-align: left;
  color: #2B3674;
  margin:0px;
  margin-bottom: 4px;
}

label {
  width: 100%;
  text-align: left;
  color: #A3AED0;
  font-size: 14px;
}
.phone-section{
  font-family: 'DM Sans', sans-serif !important;
}

.input-field {
  width: 100%;
  padding: 12px 20px;
  margin-top: 10%;
  border: 1px solid #FCDDEC;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #4318FF;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif !important;
}
.input-field::placeholder{
  color: #FCDDEC;
  font-weight: 400;
}

.send-otp-button {
  width: 100%;
  padding: 16px;
  background-color: #4a00e0;
  color: white;
  border: none;
  border-radius: 16px;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: bold;
}

.send-otp-button:hover {
  background-color: #3a00c0;
}
.verify-add{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.sebi-info {
  position: absolute;
  bottom: 15%;
  font-size: 17px;
  display: flex;
  align-items: center;
  color: #7062FF;
  gap:0px;
}

.tick-icon {
  width: 45px;
}
.sebi-info span span{
  font-weight: bold;
}


.footer-text {
  position: absolute;
  bottom: 4%;
  font-size: 13px;
  color: #A3AED0;
  width: 100%;
  text-align: left;
  font-weight: 500;
  margin-left: 15px;
}

.branding-background {
  width: 100%;
  height: 100%;
  background-image: url('../assets/login.png');
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-bottom-left-radius: 120px;
}

.branding-section {
  width: 50%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.branding-logo {
  margin-top: 25%;
  width: 120px;
  position: relative;
}

.branding-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  margin-top: 6%;
}
.branding-text span {
  font-size: 30px;
  font-weight: 700;
}

.small-logo {
  width: 30px;
  margin-left: 15px;
}

.footer-links {
  position: absolute;
  bottom: 4%;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.footer-links a:hover {
  color: #ccc;
}

.input-field.error {
  border-color: red; /* Red border for error state */
}

.message {
  color: red; /* Error message in red */
  font-size: 14px;
  margin-top: 8px;
}

/* Transition styles */
.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.timer-text {
  font-size: 14px;
  color: #4318FF;
  text-align: right;
  font-weight: 500;
  margin: 0px;
  width:50%;
}

.resend-button {
  background: none;
  border: none;
  color: #4318FF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0px;
  width:50%;
  text-align: right;
}

.resend-button:hover {
  color: #280e99;
}

.edit-number-button {
  background: none;
  border: none;
  color: #2B3674;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  width: 50%;
}

.edit-number-button:hover {
  color: #11152e;
}


/* Extra small devices (phones, <576px) */
@media (max-width: 575.98px) {
  .login-section{
    width:100%;
    margin: 0px;
  }
  .branding-section{
    width: 0%;
  }
  .logo-login {
    display: block;
    margin-top: 20px;
    margin-left: 5%;
    margin-bottom: 10%;
  }
  .logo-login-image{
    width: 20px;
  }
  .logo-login-image-title{
      height: 20px;
      position: relative;
      bottom: 0px;
      left: 12px;
  }
  .login-main-section {
    margin-left: 5%;
    width: 90%;
  }
  .footer-text {
    position: absolute;
    bottom: 4%;
    font-size: 13px;
    color: #A3AED0;
    width: 80%;
    text-align: left;
    font-weight: 500;
    margin-left: 15px;
  }
}

/* Small devices (landscape phones, 576px to 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .login-section{
    width:100%;
    margin: 0px;
  }
  .branding-section{
    width: 0%;
  }
  .logo-login {
    display: block;
    margin-top: 20px;
    margin-left: 20%;
    margin-bottom: 10%;
  }
  .logo-login-image{
    width: 40px;
  } 
  .logo-login-image-title{
      height: 30px;
      position: relative;
      bottom: 5px;
      left: 12px;
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .login-section{
    width:60%;
  }
  .branding-section{
    width: 40%;
  }
}



