/* src/components/AlgoCard.css */

.algo-card {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 15px;
    width: 21%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .algo-card:hover{
    background-color: #F7F8FF;
  }
  
  .algo-image img {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .algo-details h4 {
    color: #1B2559;
    font-size: 17px;
    font-weight: 600;
    margin: 0px 0px 0px 4px;
  }
  
  .author {
    font-size: 13px;
    color: #A3AED0;
    font-weight: 400;
    margin: 0px 0px 0px 4px;
  }
  .card-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .current-bid {
    font-size: 13px;
    font-weight: bold;
    color: #4318FF;
    margin: 15px 0px 0px 4px;
  }
  
  
  .live2 {
    background-color: #7C7C7C;
    border:none;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0px;
    border-radius: 70px;
    width:80px;
    position: relative;
    top:8px;
    cursor: pointer;
  }
  .open {
    background-color: #4318FF;
    border:none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 0px;
    border-radius: 70px;
    width:100px;
    position: relative;
    top:8px;
    cursor: pointer;
  }
  .paused {
    background-color: #4318FF;
    border:none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 0px;
    border-radius: 70px;
    width:100px;
    position: relative;
    top:8px;
    cursor: pointer;
  }
  .subscribe {
    background-color: #4318FF;
    border:none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 0px;
    border-radius: 70px;
    width:100px;
    position: relative;
    top:8px;
    cursor: pointer;
  }

  @media (max-width: 575.98px) {
    .algo-card{
        width:88%;
    }
    .algo-image img{
        height: 140px;
    }
    .algo-details h4{
        font-size: 14px;
        line-height: 1.1;
    }
    .author {
        font-size: 11px;
    }
    .card-bottom{
        display: block;
    }
    .current-bid {
        font-size: 14px;
    }
    .live2 {
        font-size: 14px;
        padding: 10px 20px;
        width:100%;
      }
      .open {
        font-size: 14px;
        padding: 10px 20px;
        width:100%;
      }
      .paused {
        font-size: 14px;
        padding: 10px 20px;
        width:100%;
      }
      .subscribe {
        font-size: 14px;
        padding: 10px 20px;
        width:100%;
      }
  }
  
  /* Small devices (landscape phones, 576px to 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .algo-card{
        width:42%;
    }
    .algo-image img{
        height: 100px;
    }
    .algo-details h4{
        font-size: 14px;
        line-height: 1.1;
    }
    .author {
        font-size: 11px;
    }
    .card-bottom{
        display: block;
    }
    .current-bid {
        font-size: 11px;
    }
  }
  
  /* Medium devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .algo-image img{
        height: 100px;
    }
    .algo-details h4{
        font-size: 14px;
        line-height: 1.1;
    }
    .author {
        font-size: 11px;
    }
    .card-bottom{
        display: block;
    }
    .current-bid {
        font-size: 11px;
    }
  }
  