  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    border-radius: 8px;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .popup-top{
    display: flex;
    flex-direction: row;
    gap:20px;
  }
  .popup-top img{
    width: 40%;
    height: auto;
  }
  .popup-header h2 {
    margin: 0;
  }
  .popup-heading{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: space-between;
    
  }
  .popup-heading h2{
    font-size: 18px;
    font-weight: 700;
    color: #1B2559;
  }
  .p-author{
    font-size: 14px;
    font-weight: 400;
    color: #A3AED0;
    margin-top: 3px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E9EDF7;
  }
  .p-risk{
    font-size: 10px;
    font-weight: 500;
    color: #FFAA0D;
    background-color: #FFF9F2;
    width: 30%;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 4px;
    margin-top: 3px;

  }
  .p-btn{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -10px;
    margin-left: 30px;
  }
  
  .p-details{
    display: flex;
    flex-direction: row;
    gap:10px;
    margin-top: 10px;
  }
  .p-hpoint{
    display: flex;
    flex-direction: column;
    gap:3px;
    width:25%;
  }
  .p-hpoint span:first-child{
    font-size: 10px;
    font-weight: 500;
    color: #B0BBD5;
  }
  .p-hpoint span:nth-child(2){
    font-size: 14px;
    font-weight: 700;
    color: #1B2559;
  }
  .p-sd{
    font-size: 13px;
    font-weight: 500;
    color: #1B2559;
    margin-top: 5px;
  }
  .p-tags{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  .p-tag{
    font-size: 14px;
    font-weight: 500;
    color: #A3AED0;
    border: 1px solid #A3AED0;
    border-radius: 20px;
    padding: 2px 10px;
    margin-right: 10px;
  }
  .popup-bottom{
    background-color: #F4F7FE;
    border-radius: 10px;
    padding: 10px 10px;
    margin: 15px 0px;
    
  }
  .popup-bottom h2{
    font-size: 14px;
    color: #1B2559;
    font-weight: bold;
  }
  .popup-bottom p{
    font-size: 10px;
    color: #1B2559;
    font-weight: 400;
  }
  .popup-bottom ul{
    font-size: 10px;
    color: #1B2559;
    font-weight: 400;
    margin-bottom: 10px;
  }

  @media (max-width: 575.98px) {
    .popup-content{
        width: 80%;
    }
    .popup-top{
        display: block;
    }
    .popup-top img {
        display: none;
    }
    .p-sd {
        font-size: 10px;
        font-weight: 400;
        margin-top: 10px;
    }
    .popup-bottom{
        display: none;
    }
    .popup-heading{
        display: block;
    }
    .popup-heading h2 {
        font-size: 14px;
        font-weight: 600;
    }
    .p-btn {
        margin-top: 0px;
        margin-left: 0px;
    }
    .p-author {
        font-size: 12px;
        margin-top: 10px;
    }
    .p-details{
        display: block;
    }
    .p-hpoint {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
        justify-content: space-between;
    }
    .p-hpoint span:nth-child(2) {
        font-size: 12px;
        font-weight: 600;
    }
    .p-tags{
        flex-direction: column;
        gap:10px;
    }
    .p-tag{
        width: auto;
        align-self: flex-start;
    }
  }
  
  /* Small devices (landscape phones, 576px to 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .popup-content{
        width: 80%;
    }
    .popup-top{
        display: block;
    }
    .popup-top img {
        display: none;
    }
    .p-sd {
        font-size: 10px;
        font-weight: 400;
    }
    .popup-bottom{
        display: none;
    }
  }
  
  /* Medium devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .popup-content{
        width: 80%;
    }
    .popup-top{
        display: block;
    }
    .popup-top img {
        display: none;
    }
    .p-sd {
        font-size: 10px;
        font-weight: 400;
    }
  }
  
  